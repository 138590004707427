import axios from "axios";
import AuthService from "./auth";

const API_URL = process.env.REACT_APP_API_URL + "/api";

class GameService {
    getVenueAndGames(){
        var auth = AuthService.getCurrentAuth();
        var config = {
            method: 'get',
            url: API_URL + "/venues",
            headers: {
                'Authorization': `Bearer ${auth.access}`,
                'Content-Type': 'application/json'
            }
        };
        return axios(config).then(response => {
            //console.log(response);
            return response.data;
        });
    }

    getPromo(promo_id){
        var auth = AuthService.getCurrentAuth();
        var config = {
            method: 'get',
            url: API_URL + `/promos/${promo_id}`,
            headers: {
                'Authorization': `Bearer ${auth.access}`,
                'Content-Type': 'application/json'
            }
        };
        return axios(config).then(response => {
            //console.log(response);
            return response.data;
        });
    }

    getEvents(promo_id){
        var auth = AuthService.getCurrentAuth();
        var config = {
            method: 'get',
            url: API_URL + `/promos/${promo_id}/events`,
            headers: {
                'Authorization': `Bearer ${auth.access}`,
                'Content-Type': 'application/json'
            }
        };
        return axios(config).then(response => {
            //console.log(response);
            return response.data;
        });
    }

    getPlayer(card_num){
        var auth = AuthService.getCurrentAuth();
        var config = {
            method: 'get',
            url: API_URL + `/players/${card_num}`,
            headers: {
                'Authorization': `Bearer ${auth.access}`,
                'Content-Type': 'application/json'
            }
        };
        return axios(config).then(response => {
            //console.log(response);
            return response.data;
        });
    }

    getPlayerCardNumberRegistered(card_num){
        var auth = AuthService.getCurrentAuth();
        var config = {
            method: 'get',
            url: API_URL + `/players/iscardnumberregistered/${card_num}`,
            headers: {
                'Authorization': `Bearer ${auth.access}`,
                'Content-Type': 'application/json'
            }
        };
        return axios(config).then(response => {
            //console.log(response);
            return response.data;
        });
    }


    postSearchPlayer(email, firstname, lastname){
        var auth = AuthService.getCurrentAuth();
        var config = {
            method: 'post',
            url: API_URL + `/players/search/`,
            headers: {
                'Authorization': `Bearer ${auth.access}`,
                'Content-Type': 'application/json'
            },
            data : JSON.stringify({
                "email": email,
                "firstname": firstname,
                "lastname": lastname
            })
        };
        return axios(config).then(response => {
            //console.log(response);
            return response.data;
        });
    }

    postNewPlayer(card_num, email, firstname, lastname, badge = ""){
        var auth = AuthService.getCurrentAuth();
                
        var post_data = null
        if(badge == ""){
            post_data = {
                "cardnumber": card_num,
                "email": email,
                "firstname": firstname,
                "lastname": lastname,
            }
        }else{
            post_data = {
                "cardnumber": card_num,
                "clubnumber": badge,
                "email": email,
                "firstname": firstname,
                "lastname": lastname,
            }
        }

        var config = {
            method: 'post',
            url: API_URL + "/players",
            headers: {
                'Authorization': `Bearer ${auth.access}`,
                'Content-Type': 'application/json'
            },
            data : JSON.stringify(post_data)
        };
        return axios(config).then(response => {
            //console.log(response);
            return response.data;
        });
    }

    postUpdatePlayer(player_id, firstname, lastname, email){
        var auth = AuthService.getCurrentAuth();
        var config = {
            method: 'post',
            url: API_URL + "/players/update/",
            headers: {
                'Authorization': `Bearer ${auth.access}`,
                'Content-Type': 'application/json'
            },
            data : JSON.stringify({
                "playerid": player_id,
                "firstname": firstname,
                "lastname": lastname,
                "email": email
            })
        };
        return axios(config).then(response => {
            //console.log(response);
            return response.data;
        });
    }

    postAddPlayerCard(player_id, card_num){
        var auth = AuthService.getCurrentAuth();
        var config = {
            method: 'post',
            url: API_URL + "/players/addplayercard/",
            headers: {
                'Authorization': `Bearer ${auth.access}`,
                'Content-Type': 'application/json'
            },
            data : JSON.stringify({
                "playerid": player_id,
                "cardnumber": card_num
            })
        };
        return axios(config).then(response => {
            //console.log(response);
            return response.data;
        });
    }

    postNewGuestPlayer(card_num, email, firstname, lastname, phonenumber){
        var auth = AuthService.getCurrentAuth();
        var config = {
            method: 'post',
            url: API_URL + "/players",
            headers: {
                'Authorization': `Bearer ${auth.access}`,
                'Content-Type': 'application/json'
            },
            data : JSON.stringify({
                "cardnumber": card_num,
                "email": email,
                "firstname": firstname,
                "lastname": lastname,
                "phonenumber": phonenumber
            })
        };
        return axios(config).then(response => {
            //console.log(response);
            return response.data;
        });
    }

    postTicket(playerid, promoid, promoperiodid, eventchoiceidslist, tiebreaker, playedatlocal){
        var auth = AuthService.getCurrentAuth();
        var config = {
            method: 'post',
            url: API_URL + "/tickets",
            headers: {
                'Authorization': `Bearer ${auth.access}`,
                'Content-Type': 'application/json'
            },
            data : JSON.stringify({
                "playerid": playerid,
                "promoid": promoid,
                "promoperiodid": promoperiodid,
                "eventchoiceidslist": eventchoiceidslist,
                "tiebreaker": tiebreaker,
                "playedatlocal": playedatlocal
            })
        };

        return axios(config).then(response => {
            //console.log(response);
            return response.data;
        });
    }

    postTicketWOTiebreaker(playerid, promoid, promoperiodid, eventchoiceidslist, playedatlocal){
        var auth = AuthService.getCurrentAuth();
        var config = {
            method: 'post',
            url: API_URL + "/tickets",
            headers: {
                'Authorization': `Bearer ${auth.access}`,
                'Content-Type': 'application/json'
            },
            data : JSON.stringify({
                "playerid": playerid,
                "promoid": promoid,
                "promoperiodid": promoperiodid,
                "eventchoiceidslist": eventchoiceidslist,
                "playedatlocal": playedatlocal
            })
        };

        return axios(config).then(response => {
            //console.log(response);
            return response.data;
        });
    }

    playGame(cardnumber, promoid, time){
        var auth = AuthService.getCurrentAuth();
        var config = {
            method: 'post',
            url: API_URL + "/players/play/",
            headers: {
                'Authorization': `Bearer ${auth.access}`,
                'Content-Type': 'application/json'
            },
            data : JSON.stringify({
                "cardnumber": cardnumber,
                "promoid": promoid,
                "currentlocaltime": time,
            })
        };

        return axios(config).then(response => {
            //console.log(response);
            return response.data;
        });
    }
}

export default new GameService();
